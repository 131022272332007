import { forwardRef } from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import Flex from "@sholdi/primitives/atoms/Flex";
import Text from "@sholdi/primitives/atoms/Text";
import Image from "@sholdi/primitives/atoms/Image";
import Link from "@sholdi/primitives/atoms/Link";
import Button from "@sholdi/primitives/atoms/Button";

const Slide = forwardRef(
  (
    {
      image,
      subtitle,
      title,
      action,
      link,
      backgroundColor,
      priority,
      className = "",
      ...props
    },
    ref,
  ) => (
    <Flex
      ref={ref}
      style={{ bg: backgroundColor }}
      className={twMerge(
        "w-full flex-col-reverse lg:flex-row items-start lg:items-center py-16 px-4 md:px-8 lg:px-16 xl:px-22",
        className,
      )}
      {...props}
    >
      <Flex className="min-h-60 mx-auto flex-col flex-1 self-center justify-between w-full pr-0 lg:pr-4 max-w-full lg:max-w-[560px]">
        <div className="h-full mb-6">
          <Text
            variant={priority ? "h1" : "h2"}
            className="mb-6 leading-10 font-bold text-4xl lg:text-5xl"
          >
            {title}
          </Text>

          <Text
            variant={priority ? "h2" : "h3"}
            className="font-normal font-body leading-7  text-base lg:text-xl"
          >
            {subtitle}
          </Text>
        </div>
        <Button
          as={Link}
          href={link}
          target="_blank"
          variant="primary"
          buttonSize="xxl"
          className="w-full md:mt-6 lg:w-fit block hover:no-underline bottom-0 text-center"
        >
          {action}
        </Button>
      </Flex>
      <Image
        src={image}
        alt="slide-image"
        // height={480}
        // width={540}
        layout="fill"
        sizes="(min-width: 768px) 30vw, (min-width: 1200px) 30vw, 33vw"
        priority={priority}
        className="relative overflow-hidden h-full max-w-full max-h-full mb-6 lg:mb-0 w-80 md:w-96 lg:w-[540px] aspect-square rounded-bl-[25%] rounded-tr-[25%] mx-auto object-cover"
      />
    </Flex>
  ),
);

Slide.propTypes = {
  priority: PropTypes.bool,
  image: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  action: PropTypes.string,
  link: PropTypes.string,
  active: PropTypes.bool,
  reverseOrder: PropTypes.bool,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
};

Slide.displayName = "Slide";

export default Slide;
